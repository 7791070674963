import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { GridContainer } from '../GeotabGridComponents';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { useWindow } from '@web-for-marketing/react-hooks';
import { breakpoints } from '@web-for-marketing/react-core';

const classes = {
    parentContainer: {
        alignItems: 'flex-start',
        width: '100%',
        zIndex: 28,
        height: '20rem',
        background: 'transparent linear-gradient(90deg, #01172980 0%, #01172900 100%) 0% 0% no-repeat padding-box;',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            height: '10.4rem',
        },
    },
    mainContainer: {
        paddingTop: '5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '4rem',
        },
    },
    link: {
        height: '100%',
        width: '100%',
        padding: '0.5rem 1rem',
        transform: 'translateX(-1rem)',
        cursor: 'pointer',
        display: 'block',
    },
};

export function IconTextBanner({ zIndex, imagePath }) {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const localWindow = useWindow();
    const background = useMemo(() => {
        let currentBackground;

        if (localWindow.innerWidth > 960) {
            currentBackground = `linear-gradient(90deg, #01172980 0%, #01172900 100%), url('${prefixStringWithBaseUrl(
                imagePath
            )}') top center / cover no-repeat scroll`;
        } else {
            currentBackground = `#01172999, url('${prefixStringWithBaseUrl(
                imagePath
            )}') top center / cover no-repeat scroll`;
        }

        return currentBackground;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagePath, localWindow]);

    return (
        <Grid
            container
            css={classes.parentContainer}
            role='banner'
            style={{
                background,
                zIndex,
            }}
        >
            <GridContainer css={classes.mainContainer}></GridContainer>
        </Grid>
    );
}

IconTextBanner.propTypes = {
    imagePath: PropTypes.string,
    zIndex: PropTypes.number,
};
