import { v2Colors, breakpoints } from '@web-for-marketing/react-core';

export const ContentRowsStyle = {
    rowIcon: {
        height: '7rem',
        width: 'auto',
    },
    rowContainers: {
        alignItems: 'flex-start !important',
        padding: '4rem 0',
        borderBottom: `1px solid ${v2Colors.core.smoke}`,
        '&:last-child': {
            borderBottom: 'unset',
        },
    },
    rowTitle: {
        marginTop: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: '100%',
            marginLeft: 0,
            margin: '2rem 0',
        },
    },
    linkTextButton: {
        color: v2Colors.core.slate,
        padding: '0.4rem 0.8rem',
        margin: '0.4rem 0',
        '& span': {
            whiteSpace: 'normal',
            textAlign: 'left',
        },
        '&:hover span': {
            textDecoration: 'underline',
        },
        '&:focus span': {
            textDecoration: 'underline',
        },
    },
    supportContent: {
        whiteSpace: 'normal !important' as 'normal',
        textAlign: 'left',
        '& span': {
            whiteSpace: 'normal !important' as 'normal',
        },
    },
} as const;
