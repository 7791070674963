import { v2Colors } from '@web-for-marketing/react-core';
import React from 'react';

export function ChevronRight(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            viewBox='0 0 8 17'
            xmlSpace='preserve'
            width='1em'
            height='1em'
            color={v2Colors.core.slate}
            {...props}
        >
            <path
                fill='currentColor'
                d='M0.51192 1.05055C0.826414 0.780984 1.29989 0.817406 1.56946 1.1319L7.56946 8.1319C7.8102 8.41277 7.8102 8.82722 7.56946 9.10809L1.56946 16.1081C1.29989 16.4226 0.826414 16.459 0.51192 16.1894C0.197426 15.9199 0.161005 15.4464 0.430571 15.1319L6.01221 8.61999L0.430571 2.10809C0.161005 1.79359 0.197426 1.32012 0.51192 1.05055Z'
            />
        </svg>
    );
}
