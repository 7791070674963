import React from 'react';
import { v2Colors } from '@web-for-marketing/react-core';

export function DateIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            data-name='DateIcon1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            style={{ color: v2Colors.core.geotab }}
            width='1em'
            height='1em'
            {...props}
        >
            <defs>
                <style>
                    {
                        '.dateStroke{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px !important;}'
                    }
                </style>
            </defs>
            <g data-name='Date Icon'>
                <g data-name='Group 644'>
                    <path
                        data-name='Path 3596'
                        className='dateStroke'
                        d='M1,8.45V23a1.72,1.72,0,0,0,1.71,1.71H23.3A1.71,1.71,0,0,0,25,23V8.45Z'
                    ></path>
                    <line data-name='Line 165' className='dateStroke' x1='9' y1='24.75' x2='9' y2='8.45'></line>
                    <line data-name='Line 166' className='dateStroke' x1='17' y1='24.75' x2='17' y2='8.45'></line>
                    <path
                        data-name='Path 3597'
                        className='dateStroke'
                        d='M25,4.83a1.71,1.71,0,0,0-1.7-1.71H2.7A1.71,1.71,0,0,0,1,4.83V8.45H25Z'
                    ></path>
                    <line data-name='Line 167' className='dateStroke' x1='1' y1='13.89' x2='25' y2='13.89'></line>
                    <line data-name='Line 168' className='dateStroke' x1='1' y1='19.32' x2='25' y2='19.32'></line>
                </g>
                <line data-name='Line 169' className='dateStroke' x1='5.73' y1='0.75' x2='5.73' y2='5.36'></line>
                <line data-name='Line 170' className='dateStroke' x1='20.27' y1='0.75' x2='20.27' y2='5.36'></line>
            </g>
        </svg>
    );
}
