import React from 'react';
import { v2Colors } from '@web-for-marketing/react-core';

const classes = {
    rotatingPlus: {
        transition: 'transform ease 200ms, color ease 200ms',
    },
    rotated: {
        transform: 'rotate(45deg)',
        '& line': {
            stroke: `${v2Colors.core.innovation} !important`,
        },
    },
};

type RotatingPlusProps = React.SVGProps<SVGSVGElement> & {
    open?: boolean;
};

export function RotatingPlus({ open, ...other }: RotatingPlusProps): JSX.Element {
    return (
        <svg
            aria-hidden
            color={v2Colors.core.slate}
            fill='none'
            data-testid='rotatingPlus'
            height='2rem'
            width='2rem'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 25.21 25.21'
            css={[classes.rotatingPlus, open ? classes.rotated : '']}
            {...other}
        >
            <g data-name='Group 761'>
                <line
                    data-name='Line 259'
                    x1='12.61'
                    y1='23.21'
                    x2='12.61'
                    y2='2'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeWidth='3px'
                />
                <line
                    data-name='Line 260'
                    x1='2'
                    y1='12.61'
                    x2='23.21'
                    y2='12.61'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeWidth='3px'
                />
            </g>
        </svg>
    );
}
