import React from 'react';
import { Grid, CardMedia } from '@mui/material';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { callToActionBannerStyle as classes } from './styleObjects/CallToActionBanner';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { GridContainer } from '../GeotabGridComponents';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { LazyLoadContainer, Heading } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { RichTextContent } from '@components/RichTextContent';
import { v2Colors } from '@web-for-marketing/react-core';
import { PageHelmetPreloadLink } from '@components/PageHelmetPreloadLink';
import { ClassNames } from '@emotion/react';
import { SectionAnimation } from '@models/section';
import { CallToActionBannerContent } from '@models/section/callToActionBannerSection';

type CallToActionBannerProps = CallToActionBannerContent & {
    animations?: SectionAnimation;
    zIndex?: number;
    headerBanner?: boolean;
    lazyLoad?: boolean;
};

export function CallToActionBanner({
    buttons = [],
    title,
    description,
    align = 'center',
    animations = {
        animationsEnabled: false,
    },
    zIndex,
    imagePath,
    backgroundPosition,
    headerBanner,
    customProps = {},
    lazyLoad,
    settings,
}: CallToActionBannerProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const imagePathWithBaseUrl = prefixStringWithBaseUrl(imagePath);
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);

    return (
        <ClassNames>
            {({ css }) => (
                <LazyLoadContainer
                    lazyload={lazyLoad}
                    lazyClass={
                        !customProps.removeGradient
                            ? css(
                                classes.gradientImageBg({
                                    customGradient: customProps.customGradient,
                                    imagePath: imagePathWithBaseUrl,
                                    backgroundPosition,
                                })
                            )
                            : ''
                    }
                    className={animations ? 'animateContainers' : ''}
                    css={[
                        classes.parentContainer,
                        customProps.removeGradient ? classes.whiteBg(zIndex) : classes.defaultBg(zIndex),
                        classes.alignedText(align),
                    ]}
                    data-testid='callToActionBanner'
                >
                    {!lazyLoad ? <PageHelmetPreloadLink as='image' href={imagePathWithBaseUrl} /> : null}
                    <GridContainer
                        alignItems={align ? align : ''}
                        justifyContent={align ? align : ''}
                        css={[classes.bannerContainer, headerBanner ? classes.smallerBanner : classes.bannerStandard]}
                        className={`${
                            animations.sectionAnimation
                                ? getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
                                : ''
                        }`}
                    >
                        <Grid
                            item
                            className={`${getAnimationStyle(
                                'titleAnimation',
                                true,
                                animations,
                                isPreviewingAnimation
                            )}`}
                            css={description ? classes.titleContainer : undefined}
                            sm={12}
                            md={7}
                        >
                            {customProps.titleImage ? (
                                <CardMedia
                                    src={prefixStringWithBaseUrl(customProps.titleImage)}
                                    component='img'
                                    css={classes.titleImage}
                                    aria-hidden={customProps.logoAltTag ? false : true}
                                    alt={customProps.logoAltTag ? customProps.logoAltTag : ''}
                                />
                            ) : null}
                            {title ? (
                                <Heading
                                    variant={headerBanner ? 'h1' : 'h2'}
                                    color={customProps.removeGradient ? 'primary' : 'inversePrimary'}
                                    css={[classes.titleTypography, classes.alignedText(align)]}
                                    data-testid='titleId'
                                >
                                    <ParseAbbreviations content={title} />
                                </Heading>
                            ) : null}
                        </Grid>
                        {description ? (
                            <Grid
                                item
                                className={
                                    getAnimationStyle(
                                        'descriptionAnimation',
                                        false,
                                        animations,
                                        isPreviewingAnimation
                                    ) as string
                                }
                                sm={12}
                                md={7}
                            >
                                <RichTextContent
                                    variant={headerBanner ? 'h3' : 'body1'}
                                    weight='regular'
                                    css={[
                                        {
                                            color: !customProps.removeGradient
                                                ? v2Colors.text.inversePrimary
                                                : v2Colors.text.tertiary,
                                        },
                                        classes.alignedText(align),
                                    ]}
                                    italic={settings?.enableItalic}
                                    data-testid='descriptionId'
                                    content={description}
                                />
                            </Grid>
                        ) : null}
                        {buttons.length ? (
                            <Grid
                                container
                                css={classes.buttonContainer}
                                direction='row'
                                alignItems='center'
                                alignContent={align === 'center' ? 'center' : 'flex-start'}
                                justifyContent={align}
                                data-testid='buttonsId'
                            >
                                {buttons.map(
                                    (
                                        {
                                            buttonText,
                                            buttonLinkPath,
                                            buttonType,
                                            buttonTarget,
                                            buttonClassName,
                                            ariaLabel,
                                            isPopout,
                                            popoutCustomSection,
                                            params = [],
                                        },
                                        index
                                    ) => {
                                        return (
                                            <Grid
                                                item
                                                key={index}
                                                css={classes.buttonItem}
                                                className={`${getAnimationStyle(
                                                    `buttonsAnimation${index}`,
                                                    false,
                                                    animations,
                                                    isPreviewingAnimation
                                                )}`}
                                            >
                                                {isPopout ? (
                                                    <PopoutButton
                                                        key={index}
                                                        text={buttonText}
                                                        color='secondary'
                                                        variant={buttonType || 'contained'}
                                                        aria-label={ariaLabel || ''}
                                                        customSectionKey={popoutCustomSection}
                                                        params={params}
                                                        classes={{
                                                            root: `${buttonClassName ? buttonClassName : ''}`,
                                                        }}
                                                    />
                                                ) : (
                                                    <CMSLink
                                                        href={buttonLinkPath}
                                                        aria-label={ariaLabel || ''}
                                                        target={getLinkTargetValue(buttonTarget)}
                                                        type='button'
                                                        variant={buttonType || 'contained'}
                                                        color='secondary'
                                                        buttonClassName={buttonClassName}
                                                    >
                                                        {buttonText}
                                                    </CMSLink>
                                                )}
                                            </Grid>
                                        );
                                    }
                                )}
                            </Grid>
                        ) : null}
                    </GridContainer>
                </LazyLoadContainer>
            )}
        </ClassNames>
    );
}
