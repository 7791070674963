import React from 'react';
import { v2Colors } from '@web-for-marketing/react-core';

export function LocationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            data-name='LocationIcon1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            style={{ color: v2Colors.core.geotab }}
            width='1em'
            height='1em'
            {...props}
        >
            <defs>
                <style>
                    {
                        '.locationStroke{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px !important;}'
                    }
                </style>
            </defs>
            <g data-name='Location Icon'>
                <path
                    data-name='Path 3598'
                    className='locationStroke'
                    d='M10,1A9.23,9.23,0,0,0,.77,10.23C.77,15.33,10,25,10,25s9.23-9.68,9.23-14.77A9.23,9.23,0,0,0,10,1Z'
                ></path>
                <path
                    data-name='Path 3599'
                    className='locationStroke'
                    d='M10,14.34a4.11,4.11,0,1,1,4.11-4.11A4.12,4.12,0,0,1,10,14.34Z'
                ></path>
            </g>
        </svg>
    );
}
