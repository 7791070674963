import React from 'react';
import { v2Colors, getRgbaColor } from '@web-for-marketing/react-core';
import { CMSLink } from '@components/CMSLink';
import { SocialIcon } from './SocialIcon';

const classes = {
    shareIcon: {
        margin: '0.5rem 0.5rem',
        padding: '0.5rem',
        '& > svg': {
            width: '1.6rem',
            height: '1.6rem',
            color: v2Colors.core.slate,
            verticalAlign: 'bottom',
            '& path': {
                fill: v2Colors.core.slate,
            },
        },
        '&:hover > svg': {
            color: v2Colors.core.geotab,
            '& path': {
                fill: v2Colors.core.geotab,
            },
        },
        '&:hover': {
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
        },
        '&:focus > svg': {
            color: v2Colors.core.geotab,
            '& path': {
                fill: v2Colors.core.geotab,
            },
        },
        '&:focus': {
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
        },
    },
} as const;

interface EditorSocialLinkProps {
    link: string;
    socialType: 'facebook' | 'instagram' | 'linkedIn' | 'twitter' | 'mail';
    name: string;
    className?: string;
}

export function EditorSocialLink({ link, socialType, name, className }: EditorSocialLinkProps): JSX.Element | null {
    const firstName = name && name.split(' ')[0];

    return name && socialType && link ? (
        <CMSLink
            rel='noopener noreferrer'
            target='_blank'
            href={link}
            aria-label={`${firstName}'s ${socialType} page`}
            title={`Visit ${firstName}'s ${socialType} page`}
            css={classes.shareIcon}
            className={className}
            data-testid='editorSocialLinkId'
            type='link'
        >
            <SocialIcon socialIconType={socialType} />
        </CMSLink>
    ) : null;
}
