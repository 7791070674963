import { breakpoints } from '@web-for-marketing/react-core';

export const threeColumnCardsStyle = {
    media: {
        minHeight: '1rem',
        height: 'auto',
        width: '100%',
    },
    card: {
        padding: '0 0 2rem',
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        borderRadius: 10,
        margin: '0 auto',
        border: 'solid 1px #DFE3EA',
        boxShadow: '0 0 0 rgba(108,127,148,0.1), 0 0 0 rgba(108,127,148,0.1)',
        transition: 'transform ease 0.75s, box-shadow ease 500ms',
        '&:hover': {
            boxShadow: '0 0 6px rgba(108,127,148,0.1), 0 10px 10px rgba(108,127,148,0.1)',
            transition: 'transform ease 750ms, box-shadow ease 750ms',
            transform: 'translateY(-10px)',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            margin: '0 auto',
            maxWidth: 450,
            '&:hover': {
                transform: 'none',
            },
        },
    },
    cardParentContainer: {
        flexWrap: 'wrap !important' as 'wrap',
        alignContent: 'center',
    },
    contentContainer: {
        padding: '3rem 3rem 0',
    },
    titles: {
        maxWidth: '72rem',
        margin: '0 auto 3rem',
    },
    cardContainer: {
        minWidth: 200,
        boxSizing: 'border-box',
        padding: '2rem 2%',
    },
    linkText: {
        position: 'absolute',
        bottom: '3rem',
        left: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            bottom: '1.5rem',
        },
    },
    bodyText: {
        paddingBottom: '6.4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingBottom: '5.2rem',
        },
    },
    noButtonBody: {
        paddingBottom: '4rem',
    },
    verticalContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 3,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '4rem',
        },
    },
    FAQCardFix: {
        paddingBottom: '9rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingBottom: '8rem',
        },
    },
    FAQButtonFix: {
        maxWidth: 'calc(100% - 4rem)',
        left: '50%',
        bottom: '3rem',
        transform: 'translateX(-50%)',
    },
    titleContainer: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexWrap: 'wrap',
            padding: 0,
        },
    },
    mobileDirection: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexDirection: 'row',
        },
    },
} as const;
