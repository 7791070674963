import { CatalogItem } from '@models/catalogItem';
import { httpGet } from './apiService';

const cameraBaseApiUrl = '/api/camera';

export async function getAllCameraNames(): Promise<string[]> {
    return (await httpGet(`${cameraBaseApiUrl}/names`)).json();
}

export async function getCameraByNames(names: string[]): Promise<CatalogItem[]> {
    const queryString = names.map((name) => `names=${encodeURIComponent(name)}`).join('&');
    return (await httpGet(`${cameraBaseApiUrl}/by-names?${queryString}`)).json();
}
