import React from 'react';
import { Grid, Button } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { v2Colors } from '@web-for-marketing/react-core';
import { Text } from '@web-for-marketing/react-ui';

interface NavigationButtonProps {
    className?: string;
    name: string;
    title: string;
    directionText: string;
    onClick: () => void;
    onKeyDown?: () => void;
}

const panelCollapseTheme = createTheme();
const classes = {
    linkContainer: {
        '&:hover span p': {
            color: `${v2Colors.core.geotab} !important`,
        },
    },
    linkText: {
        color: v2Colors.core.innovation,
        display: 'inline-block',
        fontWeight: 700,
        transition: 'color 500ms ease',
        fontSize: '1.8rem',
        lineHeight: 1.5,
        backgroundColor: 'unset !important',
        '&:hover': {
            color: v2Colors.core.geotab,
        },
        [panelCollapseTheme.breakpoints.up('xl')]: {
            fontSize: '2rem',
        },
        [panelCollapseTheme.breakpoints.down('xl')]: {
            fontSize: '1.8rem',
        },
        [panelCollapseTheme.breakpoints.down('md')]: {
            fontSize: '1.6rem',
        },
    },
};

export function NavigationButton({
    className,
    name,
    title,
    directionText,
    onClick,
    onKeyDown,
}: NavigationButtonProps): JSX.Element {
    return (
        <Grid container>
            <Button
                variant='text'
                css={classes.linkContainer}
                className={className}
                onClick={onClick}
                onKeyDown={onKeyDown}
                data-testid='navigationButtonId'
            >
                <Text css={classes.linkText}>{directionText}</Text>
                <Text variant='body1' color='tertiary'>
                    {name}
                </Text>
                <Text variant='body1' color='tertiary'>
                    {title}
                </Text>
            </Button>
        </Grid>
    );
}
