import React from 'react';
import { v2Colors } from '@web-for-marketing/react-core';

export function WatchIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            data-name='WatchIcon1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            color={v2Colors.core.innovation}
            width='1em'
            height='1em'
            {...props}
        >
            <g data-name='Group 943'>
                <path
                    data-name='Path 3749'
                    fill='currentColor'
                    d='M19.94,19.58A3,3,0,0,0,22.69,17a40.48,40.48,0,0,0,0-10,3,3,0,0,0-2.75-2.6Q16,4.15,12,4.15c-2.67,0-5.32.09-7.94.27A3,3,0,0,0,1.31,7a40.48,40.48,0,0,0,0,10,3,3,0,0,0,2.75,2.6q3.93.27,7.94.27C14.67,19.86,17.32,19.76,19.94,19.58Z'
                ></path>
                <path
                    data-name='Path 3748'
                    style={{ fill: '#FFFFFF' }}
                    d='M16.18,12.65l-6.33,4a.76.76,0,0,1-1.06-.24A.73.73,0,0,1,8.67,16V8a.77.77,0,0,1,.77-.77.78.78,0,0,1,.41.12l6.33,4a.78.78,0,0,1,.25,1.06A.72.72,0,0,1,16.18,12.65Z'
                ></path>
            </g>
        </svg>
    );
}
