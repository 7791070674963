import React, { useState, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { CatalogItem, Spec } from '@models/catalogItem';
import { DeviceComparisonSectionData } from '@models/section/deviceComparisonSection';
import { isMobileAtom, navHeightAtom } from '@atoms/appSettings';
import { getAllCameraNames, getCameraByNames } from '@services/cameraPublicService';
import { getLinkTargetValue } from '../helpers/SectionComponentHelper';
import { useUtmUrlParams } from '@hooks/useUtmUrlParams';
import { Grid, Skeleton } from '@mui/material';
import { GridContainer } from '@components/GeotabGridComponents';
import { CMSLink } from '@components/CMSLink';
import { Picture } from '@components/Picture';
import { DeviceSpecs } from './DeviceSpecs';
import { Heading, LazyLoadContainer, Select, SelectOption, Text } from '@web-for-marketing/react-ui';
import { breakpoints, v2Colors } from '@web-for-marketing/react-core';

export interface DeviceComparisonProps {
    sectionData: DeviceComparisonSectionData;
    headerSection: boolean;
    lazyLoad?: boolean;
}

interface DeviceDropdowns {
    options: SelectOption[];
    selectedValue: string;
}

const classes = {
    fullWidth: {
        width: '100%',
    },
    container: {
        maxWidth: '1280px !important',
        overflow: 'visible !important',
    },
    section: {
        '&:not(:first-child)': {
            paddingTop: '1rem !important',
        },
        '&:not(:last-child)': {
            paddingBottom: '1rem !important',
        },
    },
    ctaButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2.4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexDirection: 'column',
            gap: '1.0rem',
        },
    },
    ctaButton: {
        '&.MuiButton-root': {
            display: 'flex',
            fontSize: '2.2rem',
            [`@media (max-width: ${breakpoints.md}px)`]: {
                fontSize: '2.0rem',
            },
        },
    },
    image: {
        marginBottom: '3.4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    centerText: {
        textAlign: 'center',
    },
    dropdownContainer: (topOffset: number) =>
        ({
            position: 'sticky',
            top: topOffset,
            backgroundColor: v2Colors.core.snow,
            zIndex: 1,
            [`@media (max-width: ${breakpoints.md}px)`]: {
                top: 0,
            },
        }) as const,
} as const;

async function fetchDeviceOptions(): Promise<SelectOption[]> {
    const cameraNames = await getAllCameraNames();

    const cameraOptions: SelectOption[] = [];

    cameraNames.forEach((cameraName) => {
        cameraOptions.push({ value: cameraName, content: cameraName });
    });

    return cameraOptions;
}

function filterDeviceOptions(
    deviceOptions: SelectOption[],
    deviceSelections: string[],
    selectedDevice: string
): SelectOption[] {
    const filteredOptions = [];
    for (const option of deviceOptions) {
        if (option.value === selectedDevice || !deviceSelections.includes(option.value)) {
            filteredOptions.push(option);
        } else {
            filteredOptions.push({ ...option, disabled: true });
        }
    }
    return filteredOptions;
}

function getRandomDeviceSelection(deviceOptions: SelectOption[], deviceSelections: string[]): string {
    const filteredOptions = deviceOptions.filter((option) => !deviceSelections.includes(option.value));
    const device = filteredOptions[Math.floor(Math.random() * filteredOptions.length)].value;
    deviceSelections.push(device);
    return device;
}

export function DeviceComparison({ sectionData, headerSection, lazyLoad }: DeviceComparisonProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [navHeight] = useAtom(navHeightAtom);
    const numberOfDeviceDropdowns = isMobile ? 2 : 3;
    const [deviceOptions, setDeviceOptions] = useState<SelectOption[]>([]);
    const [devices, setDevices] = useState<CatalogItem[]>([]);
    const { title, description, ctaButton, ctaButtonPrefix, utmSource, utmMedium, utmCampaign } = sectionData;
    const utmQueryStringParams = useUtmUrlParams({ utmSource, utmMedium, utmCampaign });
    const defaultDeviceSelections = useMemo(() => {
        if (isMobile) {
            return sectionData.defaultDeviceSelections.slice(0, 2);
        } else {
            return [...sectionData.defaultDeviceSelections];
        }
    }, [isMobile, sectionData.defaultDeviceSelections]);
    const [deviceDropdowns, setDeviceDropdowns] = useState<DeviceDropdowns[]>(() =>
        defaultDeviceSelections.map((device) => ({ options: [], selectedValue: device }))
    );
    const deviceGroupedSpecs = useMemo(() => {
        // need to modify data structure of object received from backend to render specs in desired format
        const groupedSpecsMap = new Map<string, Spec[]>();

        if (devices.length) {
            Array.from({ length: devices[0].groupedSpecs.length }).forEach((_, groupedSpecIndex) => {
                if (!groupedSpecsMap.has(devices[0].groupedSpecs[groupedSpecIndex].header)) {
                    groupedSpecsMap.set(devices[0].groupedSpecs[groupedSpecIndex].header, []);
                }
                const specLength = devices[0].groupedSpecs[groupedSpecIndex].specs.length;
                Array.from({ length: specLength }).forEach((_, specIndex) => {
                    // check if any of the selected devices have a value for this spec
                    // exclude specs with '----' value for all devices being compared
                    const isValid = devices.some((device) =>
                        device.groupedSpecs
                            .filter((gs) => gs.header === devices[0].groupedSpecs[groupedSpecIndex].header)
                            .some((gs) => gs.specs.some((s, i) => specIndex === i && s.detail !== '----'))
                    );
                    // only add spec if at least one of the selected devices has a value for this spec
                    if (isValid) {
                        devices.forEach((device) => {
                            const spec = device.groupedSpecs[groupedSpecIndex].specs[specIndex];
                            const filteredSpecs = groupedSpecsMap.get(device.groupedSpecs[groupedSpecIndex].header);
                            if (filteredSpecs) {
                                filteredSpecs.push(spec);
                            }
                        });
                    }
                });
            });
        }

        return groupedSpecsMap;
    }, [devices]);

    useEffect(() => {
        fetchDeviceOptions()
            .then((options) => {
                setDeviceOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching device names', error);
            });
    }, []);

    useEffect(() => {
        if (deviceOptions.length) {
            const selectedDevices = [...defaultDeviceSelections];
            const selectedValues = defaultDeviceSelections.map(
                (device) => device || getRandomDeviceSelection(deviceOptions, selectedDevices)
            );

            setDeviceDropdowns(
                defaultDeviceSelections.map((_, i) => ({
                    options: filterDeviceOptions(deviceOptions, selectedDevices, selectedValues[i]),
                    selectedValue: selectedValues[i],
                }))
            );

            fetchSelectedDevices(selectedValues);
        }
    }, [defaultDeviceSelections, deviceOptions]);

    function fetchSelectedDevices(deviceNames: string[]): void {
        getCameraByNames(deviceNames)
            .then((devices) => {
                setDevices(devices);
            })
            .catch((error) => {
                console.error('Error fetching device data', error);
            });
    }

    function handleDeviceChange(value: string, index: number): void {
        const selectedDeviceNames = deviceDropdowns.map((dropdown, i) =>
            i === index ? value : dropdown.selectedValue
        );
        const updatedDeviceDropdowns = deviceDropdowns.map((dropdown, i) => {
            if (i === index) {
                return {
                    options: filterDeviceOptions(deviceOptions, selectedDeviceNames, value),
                    selectedValue: value,
                };
            } else {
                return {
                    options: filterDeviceOptions(deviceOptions, selectedDeviceNames, dropdown.selectedValue),
                    selectedValue: dropdown.selectedValue,
                };
            }
        });

        setDeviceDropdowns(updatedDeviceDropdowns);
        fetchSelectedDevices(selectedDeviceNames);
    }

    return (
        <GridContainer justifyContent='center' rowGap={{ xs: 2, md: 6 }} css={classes.container}>
            <Grid container direction='column' rowGap={{ xs: 2, md: 3 }} css={classes.section}>
                <Heading align='center' variant={headerSection ? 'h1' : 'h2'} css={classes.fullWidth}>
                    {title}
                </Heading>
                {description ? (
                    <Text align='center' css={classes.fullWidth}>
                        {description}
                    </Text>
                ) : null}
                <div css={classes.ctaButtonContainer}>
                    {ctaButtonPrefix ? (
                        <Heading variant='h4' component='p'>
                            {ctaButtonPrefix}
                        </Heading>
                    ) : null}
                    <CMSLink
                        type='button'
                        variant={ctaButton.buttonType}
                        href={`${ctaButton.buttonLinkPath}${utmQueryStringParams}`}
                        target={getLinkTargetValue(ctaButton.buttonTarget)}
                        aria-label={ctaButton.ariaLabel}
                        buttonClassName={ctaButton.buttonClassName}
                        css={classes.ctaButton}
                    >
                        {ctaButton.buttonText}
                    </CMSLink>
                </div>
            </Grid>
            {devices.length ? (
                <>
                    <Grid
                        container
                        justifyContent='center'
                        columnSpacing={{ xs: 2, md: 4 }}
                        css={[classes.section, classes.dropdownContainer(navHeight)]}
                    >
                        {Array.from({ length: numberOfDeviceDropdowns }, (_, i) => i).map((index) => (
                            <Grid item key={index} xs={6} md={4}>
                                <Select
                                    id={`device-${index}`}
                                    label={`Comparison Device ${index + 1}`}
                                    hiddenLabel
                                    value={
                                        deviceDropdowns[index]?.options.length
                                            ? deviceDropdowns[index].selectedValue
                                            : ''
                                    }
                                    options={deviceDropdowns[index]?.options || []}
                                    onChange={(value) => handleDeviceChange(value, index)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container columnSpacing={{ xs: 2, md: 4 }} css={classes.section}>
                        {devices.map((device, index) => (
                            <LazyLoadContainer
                                item
                                key={`${device.name}-${index}`}
                                xs={6}
                                md={4}
                                css={classes.centerText}
                            >
                                <Picture
                                    imagePath={device.imageUrl}
                                    alt={device.name}
                                    lazyLoad={lazyLoad}
                                    css={classes.image}
                                />
                                <CMSLink
                                    type='button'
                                    variant='outlined'
                                    href={device.ctaButton.url}
                                    aria-label={`${device.name} - ${device.ctaButton.text}`}
                                    buttonClassName={ctaButton.buttonClassName}
                                    target='_blank'
                                >
                                    {device.ctaButton.text}
                                </CMSLink>
                            </LazyLoadContainer>
                        ))}
                    </Grid>
                    <DeviceSpecs groupedSpecs={deviceGroupedSpecs} css={classes.section} />
                </>
            ) : (
                <Grid container columnSpacing={{ xs: 2, md: 4 }} css={classes.section}>
                    {Array.from({ length: numberOfDeviceDropdowns }).map((_, i) => (
                        <Grid container item key={i} justifyContent='center' rowGap={{ xs: 2, md: 3 }} xs={6} md={4}>
                            <Skeleton variant='text' animation='wave' height={50} width={300} />
                            <Skeleton variant='rounded' animation='wave' height={200} width={300} />
                            <Skeleton variant='text' animation='wave' height={50} width={150} />
                            <Skeleton variant='rounded' animation='wave' height={1000} width={300} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </GridContainer>
    );
}
