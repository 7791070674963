import React, { useMemo, useRef, useEffect } from 'react';
import { useStaticValues } from '@stateManagement/StaticValuesContext';

const classes = {
    video: {
        width: '100%',
        height: '100%',
    },
};

type VideoTagProps = {
    isMobile: boolean;
    videoPath?: string;
    mobileVideoPath?: string;
    autoPlay?: boolean;
    controls?: boolean;
    lazyLoad?: boolean;
    onPlay?: () => void;
    onPause?: () => void;
} & Omit<React.ComponentProps<'video'>, 'preload'>;

export function VideoTag({
    isMobile,
    videoPath,
    mobileVideoPath,
    autoPlay = false,
    controls = false,
    lazyLoad = false,
    onPlay,
    onPause,
    ...other
}: VideoTagProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const videoRef = useRef<HTMLVideoElement>(null);

    // React sets the muted property and does not guarantee that the muted attribute will appear on the video element.
    // This could cause issues in some browsers that look for the muted attribute as mentioned in https://github.com/facebook/react/issues/10389.
    // This is to enure that the muted attribute will appear on the video element.
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.setAttribute('muted', '');
        }
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            if (onPlay) {
                videoRef.current.onplay = onPlay;
            }
            if (onPause) {
                videoRef.current.onpause = onPause;
            }
        }
    }, [onPlay, onPause]);

    const resolvedVideoPath = useMemo(() => {
        if (isMobile && mobileVideoPath) {
            return prefixStringWithBaseUrl(mobileVideoPath);
        } else {
            return prefixStringWithBaseUrl(videoPath);
        }
    }, [prefixStringWithBaseUrl, videoPath, isMobile, mobileVideoPath]);

    return (
        <>
            <video
                ref={videoRef}
                muted={true}
                aria-hidden={true}
                tabIndex={-1}
                autoPlay={autoPlay}
                playsInline={autoPlay}
                controls={controls}
                loop={true}
                css={classes.video}
                src={lazyLoad ? '' : resolvedVideoPath}
                data-src={lazyLoad ? resolvedVideoPath : ''}
                {...other}
            />
        </>
    );
}
