import React from 'react';
import { Grid } from '@mui/material';
import { getVideoThumbnailImage } from '@helpers/videoDriveKeyHelper';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { WatchIcon } from '../IconComponents/WatchIcon';
import { cardTypes } from '../../helpers/ObjectTypeModels/Card';
import { Card } from '../Cards/Card';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { getAdminLanguageUrlPrefixById, getLanguageRouteByIdAndRouteKey } from '@helpers/languages';
import { Heading } from '@web-for-marketing/react-ui';
import { isAdminRoute } from '@helpers/routeHelpers';
import { useLocation } from 'react-router-dom';
import type { Video } from '@models/video';

interface VideoCardsProps {
    videos: Video[] | null;
    zIndex: number;
    lazyLoad: boolean;
}

export function VideoCards({ videos, zIndex, lazyLoad }: VideoCardsProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const location = useLocation();

    function getVideoLanguageUrlPrefix(languageId?: number): string {
        const languagePrefix = getAdminLanguageUrlPrefixById(languageId);
        const languageTypePrefix = getLanguageRouteByIdAndRouteKey('videoUrl', languageId);

        return `${languagePrefix}${languageTypePrefix}`;
    }

    return (
        <Grid container>
            <GridContainer css={{ zIndex: zIndex }} type='cards' direction='column' alignItems='flex-start'>
                {videos && videos.length ? (
                    <ChildContainer
                        breakSpaceValue={1}
                        alignItems='stretch'
                        justifyContent='flex-start'
                        lazyLoad={lazyLoad}
                        wrap='wrap'
                    >
                        {videos.map((card, index) => {
                            const cardContent = {
                                title: card.title,
                                image: {
                                    imagePath: card.imagePath
                                        ? prefixStringWithBaseUrl(card.imagePath)
                                        : getVideoThumbnailImage(card.videoKey, card.videoType),
                                },
                                buttons: [
                                    {
                                        buttonText: 'Watch video',
                                        target: '_blank',
                                        buttonIcon: <WatchIcon />,
                                        buttonLink: `${getVideoLanguageUrlPrefix(card.language)}${card.slug}`,
                                        ariaLabel: `Go to ${card.title} video page`,
                                    },
                                ],
                            };

                            return (
                                <Card
                                    card={{
                                        ...cardTypes.EXTRA_FEATURE_CARD,
                                        content: cardContent,
                                    }}
                                    key={index}
                                    data-testid={`videoCard${index}`}
                                />
                            );
                        })}
                    </ChildContainer>
                ) : (
                    <GridContainer>
                        <Grid item xs={12} md={7}>
                            <Heading variant='h3' weight='regular' color='primary' component='p'>
                                {isAdminRoute(location.pathname)
                                    ? 'Click the search box in the form to start adding videos to this section or add new videos from the Video Manager section.'
                                    : 'There are currently no videos available.'}
                            </Heading>
                        </Grid>
                    </GridContainer>
                )}
            </GridContainer>
        </Grid>
    );
}

VideoCards.defaultProps = {
    videos: [],
    animations: {
        animationsEnabled: false,
    },
};
