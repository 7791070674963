import React, { useEffect, useState } from 'react';
import { MarketplaceSolutionsSectionData } from '@models/section/marketplaceSolutionsSection';
import { logError } from '@helpers/errors';
import { getAllMarketplaceSolutions } from '@services/marketplaceFilterService';
import { MarketplaceSolution } from '@models/marketplaceFilters';
import { getAPIcall } from '@helpers/marketplaceSolutionHelper';
import { useUtmUrlParams } from '@hooks/useUtmUrlParams';
import { Heading, LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { Button, Grid } from '@mui/material';
import { classes } from './styleObjects/MarketplaceSolutionsStyles';
import { CMSLink } from '@components/CMSLink';
import { Picture } from '@components/Picture';
import { ChevronRight } from '@icon/ChevronRight';
import { ChevronLeft } from '@icon/ChevronLeft';
import { MarketplaceIcon } from '@icon/MarketplaceIcon';
import { NewTabIcon } from '@icon/NewTabIcon';
import { RichTextContent } from '@components/RichTextContent';
import { ParseAbbreviations } from '@components/ParseAbbreviations';
import { useSetAtom } from 'jotai';
import { marketplaceFilterAPIStatusAtom } from '@atoms/marketplaceSolutions';
import { SectionContainer } from './SectionContainer';

export interface MarketplaceSolutionsProps {
    sectionData: MarketplaceSolutionsSectionData;
    lazyLoad: boolean;
    sectionId: number;
    headerSection: boolean;
}

export function MarketplaceSolutions({
    sectionData,
    lazyLoad,
    sectionId,
    headerSection,
}: MarketplaceSolutionsProps): JSX.Element {
    const api = getAPIcall(sectionData.filters);
    const [solutions, setSolutions] = useState<MarketplaceSolution[]>([]);
    const [cardOrder, setCardOrder] = useState<number[]>([]);
    const [carouselStatus, setCarouselStatus] = useState<'forward' | 'reverse' | 'idle'>('idle');
    const setMarketplaceFilterAPIStatus = useSetAtom(marketplaceFilterAPIStatusAtom);
    const { utmSource, utmMedium, utmCampaign } = sectionData;
    const utmUrlParameters = useUtmUrlParams({ utmSource, utmMedium, utmCampaign });

    useEffect(() => {
        getAllMarketplaceSolutions(api)
            .then((result) => {
                const cardOrder = Array.from(Array(result.pagedList.length).keys(), (item) => item + 1);
                setCardOrder(cardOrder);
                setSolutions(result.pagedList);
                setMarketplaceFilterAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    [sectionId]: { hasError: false, solutionLength: result.pagedList.length },
                }));
                if (result.pagedList.length === 0) {
                    getMarketplaceSolutionByRegion();
                }
            })
            .catch((error) => {
                logError(error, 'fetching marketplace solutions');
                setMarketplaceFilterAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    [sectionId]: { hasError: true, solutionLength: 0 },
                }));
                getMarketplaceSolutionByRegion();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    const getMarketplaceSolutionByRegion = (): void => {
        getAllMarketplaceSolutions(
            `/api/mp_proxy/solutions/public-view-solutions?localRegion=${sectionData.filters?.region}`
        )
            .then((result) => {
                const cardOrder = Array.from(Array(result.pagedList.length).keys(), (item) => item + 1);
                setCardOrder(cardOrder);
                setSolutions(result.pagedList);
            })
            .catch((error) => {
                logError(error, 'fetching marketplace solutions by region');
            });
    };

    useEffect(() => {
        setCarouselStatus('idle');
    }, [cardOrder]);

    function changeToNextCard(): void {
        const newOrder = cardOrder.map((order) => {
            if (order === 1) {
                return solutions.length;
            } else {
                return order - 1;
            }
        });

        setCardOrder(newOrder);
        setCarouselStatus('forward');
    }

    function changeToPreviousCard(): void {
        const newOrder = cardOrder.map((order) => {
            if (order === solutions.length) {
                return 1;
            } else {
                return order + 1;
            }
        });

        setCardOrder(newOrder);
        setCarouselStatus('reverse');
    }

    function getLinkWithUTMParameters(link: string): string {
        return `${link}${utmUrlParameters}`;
    }

    return (
        <SectionContainer maxWidth={1600}>
            <Grid container justifyContent='space-between' css={classes.titleContainer}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    css={sectionData.title || sectionData.description ? classes.bottomItemMargin : null}
                >
                    {sectionData.title ? (
                        <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} css={classes.title}>
                            {sectionData.title}
                        </Heading>
                    ) : null}
                    {sectionData.description ? (
                        <RichTextContent
                            content={
                                ParseAbbreviations({
                                    content: sectionData.description,
                                    stringFormat: true,
                                }) ?? ''
                            }
                        />
                    ) : null}
                </Grid>
                {sectionData.showMarkeplaceButton ? (
                    <Grid item xs={12} md={6} container css={classes.allSolutionButtonContainer} alignItems='center'>
                        <CMSLink
                            variant='outlined'
                            css={[classes.allSolutionButton, classes.bottomItemMargin]}
                            href={getLinkWithUTMParameters(
                                sectionData.marketplaceLinkPath || 'https://marketplace.geotab.com/'
                            )}
                            type='button'
                            target='_blank'
                            className='mp-carousel'
                        >
                            <MarketplaceIcon css={classes.marketplaceIcon} />
                            Explore all solutions
                            <NewTabIcon css={classes.newTabIcon} />
                        </CMSLink>
                    </Grid>
                ) : null}
            </Grid>
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                flexWrap='nowrap'
                css={solutions.length <= 4 ? classes.carouselParentContainer : null}
            >
                <Button
                    variant='outlined'
                    css={classes.arrowButton(solutions.length)}
                    onClick={changeToPreviousCard}
                    tabIndex={-1}
                    aria-label='Show previous card'
                    aria-hidden
                    data-testid='previousCardsButton'
                >
                    <ChevronLeft />
                </Button>
                <div css={classes.carouselFixedContainer}>
                    {solutions.length ? (
                        <LazyLoadContainer
                            lazyload={lazyLoad}
                            data-testid='marketplaceCardCarousel'
                            css={[
                                classes.scrollingWrapper,
                                carouselStatus === 'idle' ? classes.idle : undefined,
                                carouselStatus === 'reverse' ? classes.reverse : undefined,
                            ]}
                        >
                            {solutions.map((solution, index) => (
                                <Grid
                                    key={solution.id}
                                    item
                                    component={CMSLink}
                                    target='_blank'
                                    href={getLinkWithUTMParameters(
                                        `https://marketplace.geotab.com/solutions/${solution.slug}`
                                    )}
                                    css={[classes.card, { order: cardOrder[index] }]}
                                    className='mp-carousel'
                                >
                                    <div css={classes.cardTextContainer}>
                                        <Grid container justifyContent='space-between' alignItems='flex-start'>
                                            <Picture
                                                alt=''
                                                lazyLoad={lazyLoad}
                                                imagePath={`https://storage.googleapis.com/geotab_mp_images/${solution.logoPath}`}
                                                mobilePath={`https://storage.googleapis.com/geotab_mp_images/${
                                                    solution.smallLogoPath || solution.logoPath
                                                }`}
                                                css={classes.picture}
                                            />
                                            <div css={classes.newTabButton}>
                                                <NewTabIcon width='1.5rem' height='1.5rem' />
                                            </div>
                                        </Grid>
                                        <Heading
                                            variant='h4'
                                            component={headerSection ? 'h2' : 'h3'}
                                            css={classes.cardTitle}
                                        >
                                            {solution.name}
                                        </Heading>
                                        <Text variant='body2' color='secondary' weight='bold'>
                                            {solution.partner.name}
                                        </Text>
                                        <Text variant='body2' css={classes.description}>
                                            {solution.briefDescription}
                                        </Text>
                                    </div>
                                </Grid>
                            ))}
                        </LazyLoadContainer>
                    ) : (
                        <div data-testid='placeholderCardCarousel' css={[classes.scrollingWrapper, classes.idle]}>
                            {Array.from([1, 2, 3, 4]).map((item) => (
                                <div key={item} css={[classes.card, classes.cardPlaceholder]}>
                                    <div css={classes.cardPlaceholderImage}></div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <Button
                    variant='outlined'
                    css={classes.arrowButton(solutions.length)}
                    onClick={changeToNextCard}
                    tabIndex={-1}
                    aria-label='Show next card'
                    aria-hidden
                    data-testid='nextCardsButton'
                >
                    <ChevronRight />
                </Button>
            </Grid>
        </SectionContainer>
    );
}
