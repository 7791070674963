import React from 'react';
import { Grid } from '@mui/material';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { imageTitleLinkCardsStyle as classes } from './styleObjects/ImageTitleLinkCardsStyles';
import { Card } from '../Cards/Card';
import { cardTypes } from '../../helpers/ObjectTypeModels/Card';
import { v2Colors } from '@web-for-marketing/react-core';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { SectionAnimation } from '@models/section';
import { XAlignValues } from '@models/editorForm';
import { ImageTitleLink } from '@models/section/customizableImageGallerySection';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';

interface ImageTitleLinkCardsProps {
    lazyLoad: boolean;
    title: string;
    animations: SectionAnimation;
    zIndex: number;
    background: boolean;
    size: number;
    align: XAlignValues;
    cards: ImageTitleLink['images'];
    headerSection: boolean;
}

export function ImageTitleLinkCards({
    cards,
    title = '',
    animations,
    zIndex,
    align,
    background,
    size,
    lazyLoad,
    headerSection,
}: ImageTitleLinkCardsProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);

    return (
        <Grid
            css={{ backgroundColor: background ? v2Colors.core.cloud : '#FFF' }}
            container
            item
            className={animations ? 'animateContainers' : ''}
            md={12}
        >
            <GridContainer
                className={`${
                    animations.sectionAnimation
                        ? getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
                        : ''
                }`}
                css={{ zIndex: zIndex }}
                direction='column'
            >
                {title ? (
                    <Grid item css={{ width: '100%' }}>
                        <Grid container>
                            <Grid item>
                                <Heading
                                    variant='h2'
                                    component={headerSection ? 'h1' : 'h2'}
                                    css={[{ textAlign: 'center', marginLeft: 'auto' }, classes.titles]}
                                >
                                    {title}
                                </Heading>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item>
                    <ChildContainer justifyContent={align} css={classes.galleryContainer}>
                        {cards.length
                            ? cards.map((tile, index) => {
                                const tileContent = {
                                    title: tile.title,
                                    titleVariant: 'body1' as const,
                                    linkTarget: getLinkTargetValue(tile.linkTarget),
                                    image: {
                                        lazyLoad,
                                        ...tile.image,
                                        mobilePath: tile.image.mobilePath,
                                    },
                                };

                                return (
                                    <Card
                                        key={`${index}-${JSON.stringify(tileContent)}`}
                                        customSize={size}
                                        card={{
                                            ...cardTypes.CONTENT_TILE_CENTERED,
                                            content: tileContent,
                                        }}
                                        href={tile.linkEnabled ? tile.linkPath : undefined}
                                    />
                                );
                            })
                            : null}
                    </ChildContainer>
                </Grid>
            </GridContainer>
        </Grid>
    );
}

ImageTitleLinkCards.defaultProps = {
    cards: [
        { title: '', linkPath: '', linkTarget: true, image: { imageAlt: '', imagePath: '' } },
        { title: '', linkPath: '', linkTarget: true, image: { imageAlt: '', imagePath: '' } },
        { title: '', linkPath: '', linkTarget: true, image: { imageAlt: '', imagePath: '' } },
    ],
    align: 'flex-start',
    animations: {
        animationsEnabled: false,
    },
    size: 2,
};
