import React from 'react';
import { Grid } from '@mui/material';
import { Heading } from '@web-for-marketing/react-ui';
import { breakpoints } from '@web-for-marketing/react-core';
import { RichTextContent } from '@components/RichTextContent';
import { ParseAbbreviations } from '../ParseAbbreviations';

interface ProfileInfoProps {
    title?: string;
    name?: string;
    description?: string;
}

const classes = {
    descriptionStyle: {
        whiteSpace: 'pre-line',
    },
    titleStyle: {
        marginBottom: '2rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '1rem',
        },
    },
    nameStyle: {
        marginBottom: 0,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '1rem',
        },
    },
} as const;

export function ProfileInfo({ name, title, description }: ProfileInfoProps): JSX.Element {
    return (
        <>
            {name || title || description ? (
                <Grid item xs={12} md={8} data-testid='profileInfoId'>
                    <Heading variant='h2' component='h1' id='popout-title' css={classes.nameStyle}>
                        {name}
                    </Heading>
                    <Heading variant='h3' weight='regular' color='tertiary' component='p' css={classes.titleStyle}>
                        {title}
                    </Heading>
                    <RichTextContent
                        content={ParseAbbreviations({ content: description, stringFormat: true }) ?? ''}
                        css={classes.descriptionStyle}
                    />
                </Grid>
            ) : null}
        </>
    );
}
