import { breakpoints, v2Colors } from '@web-for-marketing/react-core';

export const testimonialStyles = {
    testimonialSectionContainer: {
        borderRadius: '1rem',
        padding: '6.5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: 0,
            borderRadius: '0.8rem 0.8rem 0 0',
        },
    },
    darkModeBackground: {
        background: v2Colors.background.dark,
    },
    lightModeBackground: {
        background: v2Colors.background.light,
    },
    quoteContainer: {
        padding: '0 1.5rem 0 7rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            textAlign: 'left',
            padding: '4rem !important',
        },
    },
    noImageQuoteContainer: {
        textAlign: 'center',
        padding: '0 6rem',
        listStylePosition: 'inside',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            textAlign: 'left',
            padding: '4rem !important',
        },
    },
    testimonialBannerImage: {
        marginTop: '-10rem',
        borderRadius: '1rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginTop: '0',
            textAlign: 'center',
        },
    },
    testimonialQuote: {
        marginTop: '3rem',
        fontWeight: 400,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginTop: '1.6rem',
        },
    },
    quoteAuthorSectionMargin: {
        marginTop: '2.5rem',
    },
    quoteAuthorName: {
        fontWeight: 700,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'block',
            wordWrap: 'break-word',
        },
    },
    testimonialLogoImage: {
        objectFit: 'contain !important' as 'contain',
        maxHeight: '9rem',
        maxWidth: '35rem',
        objectPosition: 'left',
    },
    testimonialLogoImageAlignLeft: {
        objectPosition: 'left',
    },
    testimonialLogoImageAlignCenter: {
        objectPosition: 'center',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            objectPosition: 'left',
        },
    },
    quotationMarkIcon: {
        maxHeight: '5.5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            maxWidth: '5.5rem',
        },
    },
    quoteVerticalDivider: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    quoteAuthorTitle: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginTop: '1rem',
            display: 'block',
        },
    },
    quoteHorizontalDivider: {
        display: 'none',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'block',
            width: '2rem',
            marginTop: '2.5rem',
        },
    },
    quoteHorizontalDividerLight: {
        borderTop: '1px solid',
        borderColor: v2Colors.text.inversePrimary,
    },
    quoteHorizontalDividerDark: {
        borderTop: '1px solid',
        borderColor: v2Colors.text.primary,
    },
    hideContainer: {
        display: 'none',
    },
    swipeViewContainer: {
        maxWidth: 1400,
        width: '100%',
        overflowX: 'clip !important' as 'clip',
        '& > div > div': {
            overflow: 'visible !important' as 'visible',
        },
    },
    chevronIcon: {
        overflow: 'visible',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        padding: 0,
        margin: '0 1.5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    leftChevronIcon: {
        transform: 'rotate(180deg)',
    },
    grayIcon: {
        '& path': {
            fill: v2Colors.core.smoke,
        },
    },
} as const;
