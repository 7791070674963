import { breakpoints } from '@web-for-marketing/react-core';

export const mediaTypeStyles = {
    videoMedia: {
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '0.8rem',
        paddingTop: '56.25%',
        width: '100%',
        height: '0',
        marginBottom: '2rem',
        '& > iframe': {
            height: '100%',
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: 'calc(100% - 7.2rem)',
            margin: 'auto',
            paddingTop: 'calc(56.25% - 3rem)',
        },
    },
    galleryVideoMedia: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        borderRadius: '0.8rem',
    },
    playButton: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '70px',
        height: '70px',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
    },
} as const;
